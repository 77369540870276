<template>
  <div class="views-wrap" @scroll="headerScrollControl">
    <Header />
    <Dialog
      :type="dialog.type"
      :text="dialog.message"
      :action="dialog.action"
    />
    <Transition name="slide-fade">
      <RouterView />
    </Transition>
  </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import { useRoute, RouterView } from 'vue-router'
import Header from '@/components/layout/Header.vue'
import Dialog from './components/component/Dialog.vue'
import { useStore } from './store'

export default {
  name: 'PageComponent',
  components: {
    RouterView,
    Header,
    Dialog
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    const dialog = ref({ type: null, message: null, action: null })

    const headerScrollControl = () => {
      // scroll시 아래 라인 및 섀도우 추가
      const header = document.getElementsByClassName('header-wrap')[0]
      if (window.scrollY > 0) {
        header.classList.add('header-shadow')
      } else {
        header.classList.remove('header-shadow')
      }
    }
    onMounted(() => {
      document.addEventListener('scroll', headerScrollControl)
    })
    watch(route, () => {
      window.scrollTo({ top: 0 })
    })
    // iOS Safari SPA Routing(popState, pushState) 오류로 custom alert, confirm 사용
    watch(() => store.dialog, () => {
      dialog.value = store.dialog
    })
    return { headerScrollControl, dialog }
  }
}
</script>

<style lang="scss">
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap');
@import '@/assets/styles/commonStyle.scss';
body,
html {
  font-family: 'Pretendard';
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overscroll-behavior: contain;
}
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
button a,
ul,
li,
fieldset,
form,
label,
legend,
textarea,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
footer,
header,
nav,
section {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard';
}
.views-wrap {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin: auto;
  transition: transform 250ms ease-out;
}
</style>
