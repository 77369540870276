
import { onMounted, ref, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store/index'

export default {
  name: 'HeaderComponent',
  components: {
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const path = ref()

    const saveTime = ref()
    const isExpired = ref(false)
    const homeButtonFlg = ref(false)
    const pageTitle = computed(() => {
      return route.meta.pageTitle
    })

    const expiryExcludePath = ['/', '/scan/agreement', '/test', '/guide', '/wifi']
    const boothIdExcludedPath = ['/', '/test', '/guide', '/wifi']

    const pageMove = (page: string) => {
      if (page === 'back') {
        router.back()
      } else if (page === '/') {
        store.setDialog('confirm', t('header_move_home_alert'), () => {
          homeButtonFlg.value = true
          store.$reset()
          router.push(page)
        })
      } else {
        router.push(page)
      }
    }

    // 프로세스 진행 만료 시간 체크
    const checkSaveTime = () => {
      const saveTime = sessionStorage.getItem('saveTime')

      if (!saveTime) {
        isExpired.value = false
        return
      }
      // 개인정보 수집 동의 후 6시간 유지
      const expiryTime = new Date(Number(saveTime))
      expiryTime.setHours(expiryTime.getHours() + 6)
      const now = new Date()

      isExpired.value = expiryTime < now
    }

    onMounted(() => {
      // store.setoothId() - watch route 에서 작동
      path.value = route.path
      checkSaveTime()
    })

    watch(route, () => {
      // 모든 페이지에서 booth tml id 확인
      store.setBoothId()
      // 스크롤 초기화 (index.ts 의 beforeEach에서 initial로 초기화)
      document.documentElement.style.scrollBehavior = 'smooth'
      window.scrollTo({ top: 0 })
      // 페이지 이동 시 마다 saveTime Check
      path.value = route.path
      checkSaveTime()
    })
    watch(isExpired, () => {
      // 프로세스 진행 만료 시간 만료 시 첫 페이지로 이동
      if (!expiryExcludePath.includes(location.pathname) && isExpired.value) {
        store.setDialog('alert', t('expired_alert'), () => {
          router.push('/')
          sessionStorage.removeItem('saveTime')
        })
      }
    })
    watch(() => store.boothId, () => {
      // IP 허용 여부 확인 후 맞는 메인페이지 노출
      if (!boothIdExcludedPath.includes(location.pathname) && !store.boothId && !homeButtonFlg.value) {
        alert(t('booth_id_error_alert'))
        router.push('/')
      }
      homeButtonFlg.value = false
    })
    return { path, saveTime, pageTitle, pageMove }
  }
}
