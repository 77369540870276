import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView
  },
  {
    path: '/scan/agreement',
    name: 'agreement',
    component: () => import('../components/page/agreement/Agreement.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/passport',
    name: 'passport',
    component: () => import('../components/page/passport/ScanPassport.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/passport-confirm',
    name: 'passport-confirm',
    component: () => import('../components/page/passport/ConfirmPassport.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/remit/refund-way',
    name: 'select-refund-way',
    component: () => import('../components/page/remit/SelectRefundWay.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/remit/alipay',
    name: 'alipay-refund',
    component: () => import('../components/page/remit/AlipayRefund.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/remit/card',
    name: 'card-refund',
    component: () => import('../components/page/remit/CardRefund.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/receipt',
    name: 'receipt',
    component: () => import('../components/page/receipt/ScanReceipt.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/receiptscanner',
    name: 'receiptscanner',
    component: () => import('../components/component/ScanerReceipt.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/receipt-confirm',
    name: 'receipt-confirm',
    component: () => import('../components/page/receipt/ConfirmReceipt.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/scan/visit-customs',
    name: 'visit-customs',
    component: () => import('../components/page/receipt/VisitCustoms.vue'),
    meta: {
      pageTitle: 'Claim Tax Refund'
    }
  },
  {
    path: '/wifi',
    name: 'wifi',
    component: () => import('../views/AvailableWIFIView.vue'),
    meta: {
      pageTitle: 'Available Wi-Fi'
    }
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/GuideView.vue'),
    meta: {
      pageTitle: 'Guide'
    }
  },
  {
    path: '/404',
    name: 'error page',
    component: () => import('@/views/Error404View.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  {
    path: '/test',
    name: 'test page',
    component: () => import('@/components/page/Test.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.documentElement.style.scrollBehavior = 'initial'
  next()
})

export default router
