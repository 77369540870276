
import { onMounted, ref } from 'vue'

export default {
  name: 'MainSuccessComponent',
  setup () {
    const langList = ref(['English', '한국어', '日本語', '简体中文'])

    const changePage = (lang: number) => {
      if (lang === 0) {
        sessionStorage.setItem('lang', 'en')
      }
      if (lang === 1) {
        sessionStorage.setItem('lang', 'kr')
      }
      if (lang === 2) {
        sessionStorage.setItem('lang', 'jp')
      }
      if (lang === 3) {
        sessionStorage.setItem('lang', 'cn')
      }
      location.href = '/scan/agreement'
    }

    onMounted(() => {
      // Background Image 랜덤 노출
      const bg = document.getElementById('main')
      const random = Math.floor(Math.random() * 10)
      if (bg) {
        if (random > 8) {
          bg.style.backgroundImage = "url('/img/main_bg_1.png')"
        } else if (random > 6) {
          bg.style.backgroundImage = "url('/img/main_bg_2.png')"
        } else if (random > 2) {
          bg.style.backgroundImage = "url('/img/main_bg_3.png')"
        } else {
          bg.style.backgroundImage = "url('/img/main_bg_4.png')"
        }
      }
    })

    return { langList, changePage }
  }
}
