import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

axios.defaults.baseURL = API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'

const ApiService = {
  get: (resource: any, query: any) => {
    return axios.get(`${resource}/${query}`)
  },
  post: (resource: any, params: any) => {
    return axios.post(`${resource}`, params)
  }
}

export default ApiService
