import { defineStore } from 'pinia'
import ApiService from '@/core/ApiService'

export const useStore = defineStore('refund', {
  state: () => ({ boothId: '' as any|undefined, countrycode: [], refundResult: {} as {[key: string]: string}, refundResultList: [] as {[key: string]: string}[], error: { } as { code: string, msg: string }, dialog: { type: null, message: null, action: null } as Dialog }),
  actions: {
    // 창구 ID 조회
    setBoothId () {
      ApiService.post('/getboothtmlid', {})
        .then(({ data }) => {
          // 응답 데이터 예시
          // {
          //   "booth_tml_id": "00289"
          // }
          if (data.response_code === '100') {
            this.boothId = data
            sessionStorage.setItem('boothId', data.booth_tml_id)
          } else {
            this.boothId = undefined
            sessionStorage.setItem('boothId', '')
          }
        }).catch((err) => {
          alert('[SERVER ERROR] Can not read wifi information.')
          console.error(err)
          this.boothId = undefined
          sessionStorage.setItem('boothId', '')
        })
    },
    // 국가 코드 조회
    setCountryCodeList () {
      ApiService.post('/getcountrycode', {})
        .then(({ data }) => {
          // 응답 데이터 예시
          // {
          //   "country_list": [
          //     {
          //       "comcode": "CHN",
          //       "codename": "중국",
          //       "comfullcode": "China"
          //     },
          //     {
          //       "comcode": "JPN",
          //       "codename": "일본",
          //       "comfullcode": "Japan"
          //     }
          //   ]
          // }
          this.countrycode = data.country_list
        }).catch((err) => {
          alert('[SERVER ERROR] Can not read nationality code list.')
          console.error(err)
        })
    },
    // 반출 요청
    setRefund (receipt: {[key: string]: string}) {
      this.refundResult = {}
      // 요청 데이터 예시
      // {
      //   "qr_data": "99209287212309111450331048", *필수
      //   "booth_tml_id" : "00289", *필수
      //   "name": "KAO LIHUA",
      //   "passport_no": "133314413", *필수
      //   "nationality_code": "TWN", *필수
      //   "gender": "F",
      //   "date_of_birth": "650806",
      //   "expiry_date": "120426"
      // }
      ApiService.post('/outrequest',
        {
          qr_data: receipt.qr_data,
          booth_tml_id: receipt.booth_tml_id,
          name: receipt.name,
          passport_no: receipt.passport_no,
          nationality_code: receipt.nationality_code,
          remit_book_id: receipt.remit_book_id,
          remit_book_info: receipt.remit_book_info,
          remit_book_country_num: receipt.remit_book_country_num,
          refund_way_code: receipt.refund_way_code,
          input_type: receipt.input_type,
          sign_data: receipt.sign_data
        }
      )
        .then(({ data }) => {
          // 응답 데이터 예시
          // {
          //     "buy_serial_no": "20160873942523000080",
          //     "sell_date": "20230911",
          //     "response_code": "003",
          //     "slip_status_code": "cancelled",
          //     "total_refund_amt": 2000,
          //     "response_message": "취소된 전표입니다.",
          //     "total_sales_amt": 30000,
          //     "customs_out_no": "success"
          // }
          this.refundResult = {
            responseCode: data.response_code,
            responseMessage: data.response_message,
            buySerialNo: data.buy_serial_no,
            sellDate: data.sell_date,
            totalSalesAmt: data.total_sales_amt,
            totalRefundAmt: data.total_refund_amt,
            slipStatusCode: data.slip_status_code,
            outStatusCode: data.customs_out_no
          }
        }).catch((err) => {
          alert('[SERVER ERROR] No response to the request.')
          console.error(err)
        })
    },
    // 여권 데이터 전송
    sendPassport (passport: {[key: string]: string}) {
      // console.log('*passportocr요청데이터', passport)
      ApiService.post('/passportocr', passport)
        .then(({ data }) => {
          // console.log('*응답', data)
        }).catch((err) => {
          console.warn(err)
        })
    },
    // 여권 매핑 반출 요청
    setPassportOutReq (param: {[key: string]: string}) {
      ApiService.post('/passportoutrequest', param)
        .then(({ data }) => {
          const taxrefundSlipList = data.taxrefund_slip_list as {[key: string]: string}[]
          if (!taxrefundSlipList) {
            if (data.response_code && data.response_message) {
              this.error = { code: data.response_code, msg: data.response_message }
            }
            return
          }
          this.refundResultList = taxrefundSlipList.map(t => ({
            responseCode: t.response_code,
            responseMessage: t.response_message,
            buySerialNo: t.buy_serial_no,
            sellDate: t.sell_date,
            totalSalesAmt: t.total_sales_amt,
            totalRefundAmt: t.total_refund_amt,
            slipStatusCode: t.slip_status_code,
            outStatusCode: t.customs_out_no
          }))
        }).catch(err => {
          console.warn(err)
          this.error = {
            code: '-1',
            msg: 'Uncaught Error'
          }
        })
    },
    // custom alert, confirm
    setDialog (type?: 'alert'|'confirm', message?: string, action?: () => void) {
      this.dialog = {
        type: type ?? null,
        message: message ?? null,
        action: action ?? null
      }
    },
    resetRefundResult () {
      this.refundResult = {}
    },
    $reset () {
      this.countrycode = []
      this.refundResult = {}
      this.refundResultList = []
      this.error = { code: '', msg: '' }
    }
  }
})
