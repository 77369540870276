import { createI18n } from 'vue-i18n'
import kr from '@/core/i18n/kr.json'
import en from '@/core/i18n/en.json'
import jp from '@/core/i18n/jp.json'
import cn from '@/core/i18n/cn.json'

const i18n = createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale:
    sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en',
  messages: {
    kr,
    en,
    jp,
    cn
  }
})
export default i18n
