import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-caa1dd48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dialog-background"
}
const _hoisted_2 = {
  key: 0,
  class: "dialog"
}
const _hoisted_3 = { class: "dialog__header" }
const _hoisted_4 = { class: "dialog__title" }
const _hoisted_5 = { class: "dialog__body" }
const _hoisted_6 = { class: "dialog__text" }
const _hoisted_7 = { class: "dialog__button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade-op" }, {
      default: _withCtx(() => [
        ($setup.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-fade-y" }, {
      default: _withCtx(() => [
        ($setup.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h6", _hoisted_4, _toDisplayString($props.type === 'alert' ? 'Info' : 'Warning'), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString($props.text), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "dialog__button dialog__button--confirm",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onAction && $setup.onAction(...args)))
                }, _toDisplayString(_ctx.$t('confirm_passport_button')), 1),
                ($props.type === 'confirm')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "dialog__button dialog__button--close",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onClose && $setup.onClose(...args)))
                    }, _toDisplayString(_ctx.$t('cancel')), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}