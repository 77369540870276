
import { useStore } from '@/store'
import { PropType, Transition, computed, onUnmounted, watch } from 'vue'

export default {
  name: 'DialogVue',
  components: {
    Transition
  },
  props: {
    type: String as PropType<'alert'|'confirm'>,
    text: String,
    action: Function as PropType<() => void>
  },
  setup (props: any) {
    const store = useStore()

    const isOpen = computed(() => store.dialog.type !== null)

    const onClose = () => {
      store.setDialog()
    }

    const onAction = () => {
      if (props.action) {
        props.action()
        onClose()
      } else {
        onClose()
      }
    }
    onUnmounted(() => {
      document.body.style.overflow = 'initial'
    })
    watch(isOpen, () => {
      if (isOpen.value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'initial'
      }
    })

    return {
      onClose,
      onAction,
      isOpen
    }
  }
}
