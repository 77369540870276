<template>
  <div class="inner-wrap">
    <template v-if="isAvailbleIP">
      <MainSuccessView />
    </template>
    <template v-else>
      <ErrorIPView />
    </template>
    <Footer/>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useStore } from '@/store/index'
import { useRoute } from 'vue-router'
import ErrorIPView from '@/components/page/main/ErrorIPView.vue'
import MainSuccessView from '@/components/page/main/MainSuccessView.vue'
import Footer from '@/components/layout/Footer.vue'

export default {
  name: 'MainView',
  components: {
    ErrorIPView,
    Footer,
    MainSuccessView
  },
  setup () {
    const route = useRoute()
    const isAvailbleIP = ref(false)
    const store = useStore()

    onMounted(() => {
      if (route.path === '/') {
        // 여기서 clear 하지 않는 이유는 saveTime이 필요하기 때문
        sessionStorage.removeItem('lang')
        sessionStorage.removeItem('signature')
        sessionStorage.removeItem('boothId')
        sessionStorage.removeItem('passportData')
        sessionStorage.removeItem('scanPassportData')
        sessionStorage.removeItem('alipayUserData')
        sessionStorage.removeItem('receiptData')
        sessionStorage.removeItem('remitInfo')
      }
      // header 에서 체크
      // store.setBoothId()
    })
    watch(store, () => {
      // 허용 여부 확인 후 맞는 메인페이지 노출
      if (store.boothId) {
        isAvailbleIP.value = true
      }
    })
    return { isAvailbleIP }
  }
}
</script>

<style scoped>
.inner-wrap {
  width: 100%;
}
</style>
