
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'MainSuccessComponent',
  setup () {
    const router = useRouter()

    const changePage = () => {
      router.push('/wifi')
    }

    onMounted(() => {
      // Background Image 랜덤 노출
      const bg = document.getElementById('main')
      const random = Math.floor(Math.random() * 10)
      if (bg) {
        if (random > 8) {
          bg.style.backgroundImage = "url('/img/main_bg_1.png')"
        } else if (random > 6) {
          bg.style.backgroundImage = "url('/img/main_bg_2.png')"
        } else if (random > 2) {
          bg.style.backgroundImage = "url('/img/main_bg_3.png')"
        } else {
          bg.style.backgroundImage = "url('/img/main_bg_4.png')"
        }
      }
    })

    return { changePage }
  }
}
